@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.spinner {
  border: 5px solid transparent;
  border-top: 5px solid blue;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation-name: spin;
  animation-duration: 2s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}