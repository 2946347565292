.container {

  @media screen and (min-width: 768px) {
    width: 100%;
    max-width: 1630px;
  }
}

.container__wrap {
  padding-left: 0;
  padding-top: 90px;
  min-height: 100vh;
  transition: padding-left 0.3s;

  @include themify($themes) {
    background: themed('colorBackgroundBody');
  }

  @media screen and (min-width: 576px) {
    padding-left: 250px;
  }
}

.layout {

  &.layout--collapse {

    & + .container__wrap {
      padding-left: 0;

      @media screen and (min-width: 576px) {
        padding-left: 60px;
      }
    }
  }

  &.layout--top-navigation{

    & + .container__wrap{

      @media screen and (min-width: 576px) {
        padding-left: 0;
      }
    }
  }
}

//Add css
.eTable.card{
  .card-body{
    padding: 0;
  }
}

.form.payment__credit-cards{
  .form__form-group-id-category{
    /*.form__form-group-category{
      
    }*/
    .form__form-group-id{
      margin-left: 20px;
    }
  }
}

.table.cTable{
  width: auto;
  tr{
    td{
      border: none;
    }
    &:last-child{
      border-bottom: none;
    }
  }
  .btn.btn-sm{
    padding: 5px 15px;
    width: 100px;
  }
}

.form{
  .form__half{
    .firstRow{
      height: 50px;
    }
  }
}

.card{
  a{
    cursor: pointer;
  }
}

.rdp-input-group.input-group{
  .input-group-text{
    padding: 0 .75rem;
  }
}

.table{
  &.dropzone-bottom-table{
    margin-top: 20px;
    .badge{
      padding: .5rem;
      color: whitesmoke;
    }
  }
  th{
    font-weight: bolder;
    border: none;
  }
  tr:last-child{
    border-bottom: 1px solid #dee2e6;
  }
  .btnClear{
    button{
      border: none;
      background: none;
    }
  }
}

.cross {
  position: relative;
  display: inline-block;
  font-size: 2em;
}
.cross::before, .cross::after {
  content: '';
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.cross::before {
  border-bottom: 3px solid red;
  -webkit-transform: skewY(-15deg);
  transform: skewY(-15deg);
}
.cross::after {
  /*border-bottom: 2px solid blue;
  -webkit-transform: skewY(10deg);
  transform: skewY(10deg);*/
}

.cardPrice{
  /*display: inline-flex;*/
  border-bottom: 1px dashed;
  /*@media screen and (max-width: 768px) {
    
    
  }

  @media screen and (min-width: 768px) {
  }*/
}

.alogo{
  width: 140px;
  height: 50px;
}

.border-table{
  empty-cells: show;
  &.contact{
    td{
      width: 50%;
    }
  }
  tr,td{
    border:1px solid black;
    padding: 1%;
  }
  &.rForm{
    td:first-child,th{
      text-align: center;
    }
  }
}

.white-container
{
  padding: 10px;
  background-color: white;
}

 .user-form.card-body{
   padding: 0;
 }
.dropzone__drop-here{
  color:#31b975;
}
.pricing-card__price{
  font-size: 36px;
}
h3.pricing-card__plan{
  font-size: 22px;
}

.btn.disabled,.btn.btn-primary.disabled{
  background-color: #70bbfd;
  border-color: #70bbfd;
  color: #f5f5f5;
}
/*.dropzone__img{
  background: url(../../shared/img/xml.png) no-repeat center;
}*/
//background: url(../../shared/img/bg_404.png) no-repeat center;