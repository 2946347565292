.pricing-card {
  text-align: center;

  &.pricing-card--primary {
    border-top: 5px solid $color-blue;
  }

  &.pricing-card--info {
    border-top: 5px solid $color-accent;
  }

  &.pricing-card--warning {
    border-top: 5px solid $color-yellow;
  }

  &.pricing-card--danger {
    border-top: 5px solid $color-red;
  }

  hr {
    margin: 0;

    @include themify($themes) {
      border-top-color: themed('colorFieldsBorder');
    }
  }
}

.pricing-card__body {
  padding: 30px 20px;
}
.card-body-justified {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-card__img {
  height: 70px;
  margin-bottom: 10px;
}

.pricing-card__plan {
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 25px;
}

.pricing-card__price {
  margin-top: 5px;
  font-weight: 300;
  font-size: 36px;
  line-height: 36px;

  span {
    font-size: 12px;
  }
}

.pricing-card__feature--inactive {
  color: #bbbbbb;
}

.pricing-card__button {
  margin-top: 25px;
  max-width: 160px;
  width: 100%;
}
